<template>
	<v-sheet flat>
        <render-content id="support"></render-content>
	</v-sheet>
</template>

<script>
  import RenderContent from "@c/ui/RenderContent"
  export default {
    name: 'Support',
    components: {
      RenderContent
    },
  }
</script>
